<template>
  <b-row v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="content-header">
    <!-- Content Left -->
    <b-col class="content-header-left mb-2" cols="12" md="9">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $route.meta.pageTitle }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon icon="HomeIcon" size="16" class="align-text-top" />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple
  },
  computed: {
    breadcrumb() {
      const routeParams = this.$route.params
      const items = []
      const breadcrumb = this.$route.meta.breadcrumb

      breadcrumb.forEach(item => {
        if (typeof item.resolve === 'function') {
          const resolved = item.resolve()
          item.to = resolved
          item.to.params = Object.fromEntries(resolved.params.map(key => [key, routeParams[key]]))
        }

        items.push(item)
      })
      return items
    }
  }
}
</script>
